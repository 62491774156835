import { Link } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import bgFooter from "../../assets/image/png/subscribe-pattern.png";
import { ButtonIcon, Section, Title } from "../../components/Core";


const SectionStyled = styled(Section)`
  &.pattern::before {
    position: absolute;
    bottom: -150px;
    content: "";
    width: 120%;
    height: 150%;
    background: url(${bgFooter}) bottom center no-repeat;
    background-size: cover;
    z-index: -1;
  }
`;

const Contact = ({ pattern = true }) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled
        className={`position-relative ${pattern ? "pattern" : ""}`}
      >
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="6" className="text-center">
              <div className="text-center my-5">
                <Title variant={"secSm"}>
                  Do you want your app listed here too?
                  </Title>
              </div>
              <div className="text-center">
                <Link to={"/submission/create"}>
                  <ButtonIcon icon={"icon-heart-2-2"}>
                    Submit Your App
                    </ButtonIcon>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Contact;
