import React from "react";
import { Element } from "react-scroll";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/apps/Hero";
import Apps from "../sections/apps/Apps";
import Contact from "../sections/apps/CTA";
import { Helmet } from "react-helmet";
import SEO from "../components/SEO";

const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>Apps</title>
        </Helmet>
        <Hero />
        <Element name="apps">
          <Apps />
        </Element>
        <Contact />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
