import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Box } from "../../components/Core";

const BoxStyled = styled(Box)`
  transition: 0.4s;

  &:hover {
    transform: scale(0.8) rotate(-16deg);
    box-shadow: 0 32px 74px rgba(68, 77, 136, 0.2);
    overflow: hidden;
  }
  border-radius: 50px;
`

const RotateImg = ({ link = "/", imgSrc, alt = "", ...rest }) => {
  return (
    <>
      <BoxStyled {...rest}>
        <Link to={link} className="d-block">
          <img src={imgSrc} alt={alt} className="w-100" />
        </Link>
      </BoxStyled>
    </>
  );
};
export default RotateImg;
